(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/match-info-redux/assets/sport-event-reducer.js') >= 0) return;  svs.modules.push('/components/sportinfo/match-info-redux/assets/sport-event-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  createReducer
} = RTK;
const {
  fetchMatchInfo,
  fetchMatchData
} = svs.components.sportinfo.matchInfoRedux.actions;
const {
  normalizeProviders
} = svs.components.sport.matchInfoRedux;
const {
  ReducerState
} = svs.components.sport.tipsenShared;
const {
  loadOpenedDrawsByEngine,
  loadDrawByProduct,
  updateDraw
} = svs.components.sport.drawsRedux.actions;
const getSportEventFromMatchId = (state, matchId) => {
  if (!state[matchId]) {
    state[matchId] = {
      matchId,
      lastFetched: null,
      eventState: ReducerState.Init,
      oddsState: ReducerState.Init,
      dataState: ReducerState.Init
    };
  }
  return state[matchId];
};
const addReducerStateFields = (state, sportEvents) => {
  const _sportEvents = {};
  if (sportEvents) {
    const keys = Object.keys(sportEvents);
    keys.forEach(matchId => {
      const match = getSportEventFromMatchId(state, matchId);
      _sportEvents[matchId] = _objectSpread(_objectSpread(_objectSpread({}, match), sportEvents[matchId]), {}, {
        dataState: ReducerState.Done,
        eventState: ReducerState.Done,
        oddsState: ReducerState.Done
      });
    });
  }
  return _sportEvents;
};
const sportEventReducer = createReducer({}, builder => {
  builder.addCase(fetchMatchInfo.pending, (state, action) => {
    const {
      matchId
    } = action.meta.arg;
    const sportEvent = getSportEventFromMatchId(state, matchId);
    sportEvent.oddsState = ReducerState.Pending;
    sportEvent.eventState = ReducerState.Pending;
  }).addCase(fetchMatchInfo.rejected, (state, action) => {
    const {
      matchId
    } = action.meta.arg;
    const sportEvent = getSportEventFromMatchId(state, matchId);
    sportEvent.oddsState = ReducerState.Rejected;
    sportEvent.eventState = ReducerState.Rejected;
  }).addCase(fetchMatchInfo.fulfilled, (state, action) => {
    var _action$meta$enhanche;
    const {
      matchId
    } = action.meta.arg;
    const sportEvent = getSportEventFromMatchId(state, matchId);
    const [eventInfo, odds] = action.payload;
    sportEvent.eventState = eventInfo.error ? ReducerState.Rejected : ReducerState.Done;
    sportEvent.oddsState = odds.error ? ReducerState.Rejected : ReducerState.Done;
    sportEvent.lastFetched = (_action$meta$enhanche = action.meta.enhanchedData) === null || _action$meta$enhanche === void 0 ? void 0 : _action$meta$enhanche._svsTimestamp;
    if (sportEvent.eventState === ReducerState.Done) {
      for (const [prop, value] of Object.entries(eventInfo.match)) {
        sportEvent[prop] = value;
      }
    }
  }).addCase(fetchMatchData.pending, (state, action) => {
    const matchId = action.meta.arg;
    const match = getSportEventFromMatchId(state, matchId);
    match.dataState = ReducerState.Pending;
  }).addCase(fetchMatchData.rejected, (state, action) => {
    const matchId = action.meta.arg;
    const match = getSportEventFromMatchId(state, matchId);
    match.dataState = ReducerState.Rejected;
  }).addCase(fetchMatchData.fulfilled, (state, action) => {
    const matchId = action.meta.arg;
    const match = getSportEventFromMatchId(state, matchId);
    const matchData = action.payload;
    if (matchData.error) {
      match.dataState = ReducerState.Rejected;
    } else {
      var _matchData$sportEvent;
      const providerIds = normalizeProviders((_matchData$sportEvent = matchData.sportEvent) === null || _matchData$sportEvent === void 0 ? void 0 : _matchData$sportEvent.providerIds);
      match.externalId = _objectSpread({}, providerIds);
      match.providerIds = providerIds;
      match.dataState = ReducerState.Done;
    }
  }).addCase(loadOpenedDrawsByEngine.fulfilled, (state, action) => {
    const sportEvents = addReducerStateFields(state, action.payload.SportEvents);
    Object.assign(state, sportEvents);
  }).addCase(updateDraw, (state, action) => {
    var _action$meta;
    if ((_action$meta = action.meta) !== null && _action$meta !== void 0 && _action$meta.isLightUpdate) {
      return state;
    }
    const sportEvents = addReducerStateFields(state, action.payload.SportEvents);
    Object.assign(state, sportEvents);
  }).addCase(loadDrawByProduct.fulfilled, (state, action) => {
    const sportEvents = addReducerStateFields(state, action.payload.draws.SportEvents);
    Object.assign(state, sportEvents);
  });
});
setGlobal('svs.components.sportinfo.matchInfoRedux.sportEventReducer', sportEventReducer);

 })(window);